<template>
  <div class=container>
      <table class="table" width="73%" border="0" cellspacing="30" cellpadding="0">
          <tbody><tr>
            <td><div align="center"> <font size="+2">Demo: High Dynamic Range Imaging </font></div></td>
          </tr>
          <tr>
            <td valign="baseline"><em>
              </em><div align="left">
                <dl>
                  <p align="left"><font size="2">This gallery shows examples of radiance images computed using the <router-link to="/repository/RASCAL/software">RASCAL </router-link></font>algorithm. Though the original (captured) images are 8 bits per color channel, the computed radiance images are 16 bits per channel. Since it is difficult to visualize 16 bit images on most computer screens, we have developed an interactive tool called the Detail Brush. This allows you to explore the richness of visual information embedded in each computed radiance image.
                  </p><p>
                    </p><center>
                      <font size="2"><b>Click on any of the images below for an interactive demonstration of the Detail Brush. <br>
                      <br>
                      </b></font>
                    </center>
                  <p></p>
                  <div align="center"><span class="style3">Computed High Dynamic Range Images</span>
                    <br>
                    <br>
</div>
                  <div align="center">
                    <center>
                      <table border="0" cellspacing="7">
                        <tbody><tr>
                          <td><div align="center"><em><a href="https://cave.cs.columbia.edu/old/software/rascal/rview/view.7708.1-5.html"><img src="https://cave.cs.columbia.edu/old/software/rascal/image/7708.1-5.jpg" border="0" width="88" height="55"></a><font size="1"><br>
                          452KB</font></em></div></td>
                          <td><div align="center"><em><a href="https://cave.cs.columbia.edu/old/software/rascal/rview/view.7708.6-11.html"><img src="https://cave.cs.columbia.edu/old/software/rascal/image/7708.6-11.jpg" border="0" width="88" height="55"></a><font size="1"><br>
                          452KB</font></em></div></td>
                          <td><div align="center"><em><a href="https://cave.cs.columbia.edu/old/software/rascal/rview/view.7710.13-18.html"><img src="https://cave.cs.columbia.edu/old/software/rascal/image/7710.13-18.jpg" border="0" width="88" height="55"></a><font size="1"><br>
                          452KB</font></em></div></td>
                        </tr>
                        <tr>
                          <td><div align="center"><em><a href="https://cave.cs.columbia.edu/old/software/rascal/rview/view.7708.18-23.html"><img src="https://cave.cs.columbia.edu/old/software/rascal/image/7708.18-23.jpg" border="0" width="55" height="88"></a><font size="1"><br>
                          452KB</font></em></div></td>
                          <td><div align="center"><em><a href="https://cave.cs.columbia.edu/old/software/rascal/rview/view.7708.24-29.html"><img src="https://cave.cs.columbia.edu/old/software/rascal/image/7708.24-29.jpg" border="0" width="55" height="88"></a><font size="1"><br>
                          452KB</font></em></div></td>
                          <td><div align="center"><em><a href="https://cave.cs.columbia.edu/old/software/rascal/rview/view.7710.1-6.html"><img src="https://cave.cs.columbia.edu/old/software/rascal/image/7710.1-6.jpg" border="0" width="55" height="85"></a><font size="1"><br>
                          439KB</font></em></div></td>
                        </tr>
                      </tbody></table>
                    Click an image to view. <br>
                    <br>
                    <br>
                    <br>
                    </center>
                  </div>
                  <div align="center"><span class="style3">Example: Input and Output Images</span><em>                    <br>
                  </em></div>
                  <div align="center" class="style40">
                    <center>
                      <table border="0">
                        <tbody><tr>
                          <td colspan="5"><p align="center">Original Images (8bit)</p></td>
                                  <td><p align="center">Computed Image (16bit)</p></td>
                        </tr>
                        <tr>
                          <td><p align="center"><a href="https://cave.cs.columbia.edu/old/software/rascal/rview/view.7708.6.html"><font size="1"><img src="https://cave.cs.columbia.edu/old/software/rascal/image/s7708_06.0.jpg" border="0" width="88" height="55">452KB</font></a></p></td>
                                  <td><p align="center"><a href="https://cave.cs.columbia.edu/old/software/rascal/rview/view.7708.7.html"><font size="1"><img src="https://cave.cs.columbia.edu/old/software/rascal/image/s7708_07.0.jpg" border="0" width="88" height="55">452KB</font></a></p></td>
                                  <td><p align="center"><a href="https://cave.cs.columbia.edu/old/software/rascal/rview/view.7708.9.html"><font size="1"><img src="https://cave.cs.columbia.edu/old/software/rascal/image/s7708_09.0.jpg" border="0" width="88" height="55">452KB</font></a></p></td>
                                  <td><p align="center"><a href="https://cave.cs.columbia.edu/old/software/rascal/rview/view.7708.10.html"><font size="1"><img src="https://cave.cs.columbia.edu/old/software/rascal/image/s7708_10.0.jpg" border="0" width="88" height="55">452KB</font></a></p></td>
                                  <td><p align="center"><a href="https://cave.cs.columbia.edu/old/software/rascal/rview/view.7708.11.html"><font size="1"><img src="https://cave.cs.columbia.edu/old/software/rascal/image/s7708_11.0.jpg" border="0" width="88" height="55">452KB</font></a></p></td>
                                  <td><p align="center"><a href="https://cave.cs.columbia.edu/old/software/rascal/rview/view.7708.6-11.html"><img src="https://cave.cs.columbia.edu/old/software/rascal/image/7708.6-11.jpg" border="0" width="88" height="55"><font size="1">452KB</font></a></p></td>
                        </tr>
                      </tbody></table>
                    </center>
                  </div>
                  <div align="center"><br>
                      Click images to compare</div>
                </dl>
              </div>
            </td>
          </tr>
          <tr>
            <td valign="baseline"><div align="center">              <em>
              <div align="left">                </div>
            </em>
                <div align="left"></div>
                <div align="left"><hr align="left" size="4">
                  <p>last updated :
                    <!--webbot bot="Timestamp" startspan
s-type="EDITED" s-format="%m/%d/%y" -->
                    07/29/99
                    <!--webbot
bot="Timestamp" i-checksum="14106" endspan -->
                  </p>
                  <p>[<router-link to="/repository/RASCAL">Goto RASCAL</router-link>]<span class="style1">
                    <!--webbot
bot="Timestamp" i-checksum="14106" endspan -->
                  </span></p>
                </div>
            </div></td>
          </tr>
        </tbody></table>
  </div>
</template>

<script>
export default {

}
</script>

<style>
.container{
    width:70%;
    margin-left:auto;
    margin-right:auto;
    text-align: left;
	font-size: 18px;
}
.table{
        margin:auto;
}
</style>